<template>
  <b-overlay :show="loading">
    <div class="home-container">
      <pagination :implementSearch="false" :meta="projectLeadersMeta" @refresh="onRefresh" @change-page="onChangePage">
        <b-button v-if="currentAdmin.super" slot="action" :to="{name: `project-leader.store`}" class="btn btn-label-success btn-sm border-0">
          {{ $t("COMMON.CREATE") }}
        </b-button>
      </pagination>
      <b-row>
        <b-col sm="6" md="3" v-for="item in projectLeaders" :key="item.id" class="d-flex">
          <portlet title=" " body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm" foot-class="mt-auto">
            <template v-if="item.qr_code" v-slot:toolbar>
              <b-button :id="`qr-code-${item.id}`" variant="label-success" size="sm" class="btn-upper btn-icon btn-icon-md"><i class="fa fa-qrcode"></i></b-button>
              <b-popover placement="bottom" :target="`qr-code-${item.id}`" triggers="hover focus" no-fade>
                <b-img :src="item.qr_code.image_url" />
              </b-popover>
            </template>
            <template v-slot:body>
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__head">
                    <div class="kt-widget__media">
                      <b-avatar rounded size="50px" text="!" :src="item.avatar_image_preview" />
                    </div>
                    <div class="kt-widget__info">
                      <a class="kt-widget__username">
                        {{ item.hasOwnProperty(lang) ? item[lang].full_name : '...' }}
                      </a>
                    </div>
                  </div>
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact cursor-default">
                        <span class="kt-widget__label">{{ $t("COMPONENTS.FORMS.PROJECT_LEADER_FORM.FORM.LABEL.phone") }}</span>
                        <span class="kt-widget__data">{{ item.phone }}</span>
                      </div>
                      <div class="kt-widget__contact cursor-default" v-if="item.hasOwnProperty(lang) && item[lang].describe">
                        <span class="kt-widget__data">{{ item[lang].describe }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:foot v-if="currentAdmin.super">
              <b-button-group>
                <b-button block variant="primary" :pressed="false" :to="{name: `project-leader.edit`, params: {id: item.id}}">{{ $t("COMMON.FORM.BUTTONS.EDIT") }}</b-button>
                <b-button variant="danger" :pressed="false" @click="onChangeSwitch(item.id, 'is_show', item.is_show)"><span class="fa " :class="[item.is_show ? 'fa-eye' : 'fa-eye-slash']"></span></b-button>
              </b-button-group>
            </template>
          </portlet>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import Error from "@v@/partials/widgets/Error";
  import Portlet from "@v@/partials/content/Portlet";
  import pagination from "@v@/components/paginate";
  import projectLeaderMixin from "@m@/projectLeader.mixin";
  import {mapGetters} from 'vuex';
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import {SET_PROJECT_LEADER_ERROR, SET_PROJECT_LEADERS} from "../../../../store/modules/projectLeader.module";
  export default {
    name: "index",
    components: { Error, Portlet, pagination },
    mixins: [projectLeaderMixin, msgBoxConfirmMixin, commonMethodMixin],
    props: {},
    created() {
      this.autoLoadHomeData(1);
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.PROJECT_LEADER.HOME.BREAD_CRUMBS_TITLES.FIRST_TITLE"), route: {name: 'project-leader.home'}});
      BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.PROJECT_LEADER.HOME.BREAD_CRUMBS_TITLES.CURRENT_TITLE")});
    },
    watch: {},
    computed: {
      ...mapGetters([
         'projectLeaders', 'projectLeadersMeta', 'projectLeadersPage', 'currentAdmin'
      ]),
      lang(){
        return this.$i18n.locale || 'ZH-UG';
      },
    },
    data(){
      return {
        loading: false,
        page: 1
      }
    },
    methods: {
      autoLoadHomeData(rootPage = null){
        this.loading = true;
        let {projectLeadersPage} = this;
        if( !rootPage ){
          this.page = projectLeadersPage
        }else if (rootPage) {
          this.page = rootPage
        }
        this.getProjectLeaders(this.page)
          .catch(err=>{
            console.info('getProjectLeader.err: ', err);
          }).finally(()=>{
            this.loading = false;
          })
      },
      onChangePage(e){
        this.autoLoadHomeData(e.page);
      },
      onRefresh(){
        this.autoLoadHomeData(1);
      },
      onChangeSwitch(id, column, isShow){
        this.msgBoxConfirm({
          message: isShow ? this.$t("PAGES.PROJECT_LEADER.HOME.CONFIRM_TIPS.CLOSE"): this.$t("PAGES.PROJECT_LEADER.HOME.CONFIRM_TIPS.OPEN"),
          confirmCallback: confirm=>{
            if ( confirm ){
              this.switchColumn({
                resource: 'project-leaders',
                id, column, mutationType: SET_PROJECT_LEADER_ERROR,
                begin: ()=>{ this.loading = true },
                after: ()=>{ this.loading = false },
              }).then((res)=>{
                let { is_show } = res.data;
                let { projectLeaders, projectLeadersMeta } = this;
                let tmp = projectLeaders.map(item=>{
                  if( item.id === id ){
                    item.is_show = is_show;
                  }
                  return item;
                });
                this.$store.commit(SET_PROJECT_LEADERS, { data: tmp, meta: projectLeadersMeta});
              })
            }
          }
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home-container {
    .btn-group, .btn-group-vertical {
      display: flex !important;
    }
  }

</style>
