import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import { GET_PROJECT_LEADER, GET_PROJECT_LEADERS, STORE_PROJECT_LEADER, UPDATE_PROJECT_LEADER } from "../store/modules/projectLeader.module";
export  default {
  methods: {
    storeProjectLeader(formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(STORE_PROJECT_LEADER, formData), actions)
    },
    getProjectLeader(id, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_PROJECT_LEADER, id), actions)
    },
    updateProjectLeader(id, formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(UPDATE_PROJECT_LEADER, { id, formData }), actions)
    },
    getProjectLeaders(page = 1, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_PROJECT_LEADERS, page), actions)
    },
  }
}
